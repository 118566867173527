/* @font-face {
  font-family: 'PantonRustHeavy-GrSh';
  src: url('/public/PantonRustHeavy-GrSh.woff') format('woff');
}

header {
  font-family: 'PantonRustHeavy-GrSh', sans-serif;
} */
@font-face {
  font-family: 'Deutsch';
  src: url('/src/fonts/Deutsch.woff') format('woff');
}

@font-face {
  font-family: 'PlainGermanica';
  src: url('/src/fonts/PlainGermanica.woff') format('woff');
}

header {
  font-family: 'PlainGermanica', sans-serif !important;
  font-size: 4rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'PlainGermanica', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Deutsch',
    monospace;
}