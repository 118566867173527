/* 1950s and War-themed styles */
:root {
  --primary-color: #141414;
  --secondary-color: #141414;
  --accent-color: #c70601;
  /* Peru */
  --text-color: #c70601;
  /* Beige */
  --background-color: #141414;
  /* Dark slate gray */
}

body {
  font-family: 'PlainGermanica' , Courier, monospace !important;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.background {
  max-width: 100%;
  min-width: 80%;
  min-height: 100%;
}

/* Header styles */
.header {
  background-color: var(--primary-color);
  padding: 1rem;
  letter-spacing: 0.5rem;
  animation: fade-in 0.5s ease-out;
  width: 100%;
  box-sizing: border-box;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
}

.header-content {
  flex-grow: 1;
}

.header h1 {
  margin: 0;
  font-size: 3rem;
  color: var(--accent-color);
}

.header nav ul li a {
  color: var(--text-color);
  text-decoration: none;
  font-weight: bold;
}

.header-image {
  height: 200px;
  width: auto;
}

.header-image:hover {
  cursor: pointer;
}

.header-image.left {
  animation: fade-in 0.5s ease-out;
}

.header-image.right {
  animation: fade-in 0.5s ease-out;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(-360deg); }
}

@keyframes spin2 {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.header-image.spin {
  animation: spin 3s linear infinite;
}

.header-image.spin2 {
  animation: spin 3s linear infinite reverse;
}

/* War Gallery buttons */
.war-gallery-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.war-gallery-buttons button {
  background-color: var(--accent-color);
  color: var(--background-color);
  border: none;
  margin: 1rem 1rem;
  font-size: 2.5rem;
  width: 18rem;
  cursor: pointer;
  font-weight: bold;
  font-family: 'PlainGermanica', Arial, sans-serif !important;
  /* border: 2px solid white; */
  animation: fade-in 0.5s ease-out;
}

/* About and Features styles */
.about p {
  font-size: 1.2em;
}

.about,
.features {
  padding: 1rem;
  animation: fade-in 0.5s ease-out;
}

.features ul {
  list-style-type: none;
  padding: 0;
}

.features ul li {
  margin-bottom: 0.5rem;
}

/* War Gallery styles */
.war-gallery {
  background-color: var(--secondary-color);
  padding: 0.5rem;
  animation: fade-in 0.5s ease-out;
  align-self: center;
}

.war-gallery h2 {
  color: var(--accent-color);
  margin-bottom: 1rem;
}

/* Collections styles */
.collections {
  padding: 1rem;
  animation: fade-in 0.8s ease-out;
}

.page-collection-button {
  background-color: var(--accent-color);
  color: var(--background-color);
  border-radius: 5px;
  font-size: 1.2rem;
  width: 100%;
  max-width: 10rem;
  cursor: pointer;
  font-family: 'PlainGermanica', Arial, sans-serif !important;
  animation: fade-in 0.5s ease-out;
  margin: 0.5rem;
  padding: 0.5rem;
}

.collections h1 {
  color: var(--accent-color);
  margin-bottom: 1rem;
  text-shadow: -1px 0 rgb(255, 255, 255), 0 1px rgb(255, 255, 255), 1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
  font-family: Deutsch;
  letter-spacing: 0.3rem;
  font-size: 2rem;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.image-gallery figure {
  max-width: 100%;
  width: 300px;
  animation: fade-in 0.5s ease-out;
}

.image-gallery img {
  width: 100%;
  height: auto;
  border: 2px solid var(--accent-color);
}

.image-gallery figcaption {
  margin-top: 0.5rem;
  font-style: italic;
}

.image-container-home {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.image-container-home figure {
  margin: 0;
  width: 100%;
  max-width: 300px;
  animation: fade-in 0.5s ease-out;
}

.image-container-home img {
  width: 100%;
  height: auto;
  border: 2px solid var(--accent-color);
}

.image-container-home figcaption {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  font-style: italic;
}

.image-container-home figcaption p {
  text-shadow: 0.5px 0.5px 1px rgb(0, 0, 0), 0 0 0.05em rgb(95, 95, 95), 0 0 0.2em rgb(95, 95, 95);
}

/* Footer styles */
.footer {
  background-color: var(--primary-color);
  padding: 1rem;
  margin-top: 2rem;
  animation: fade-in 0.5s ease-out;
  width: 100%;
  box-sizing: border-box;
}

.footer nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer nav a {
  color: var(--text-color);
  text-decoration: none;
  margin: 0.5rem;
}

.footer img {
  height: 3rem;
  width: auto;
  margin: 0.5rem;
  cursor: pointer;
}

/* Keyframe animation for fade-in effect */
@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* WalletConnector CSS */
.wallet-connect-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wallet-connect-buttons button {
  width: 100%;
  max-width: 9rem;
  background-color: var(--accent-color);
  color: var(--background-color);
  border: none;
  margin: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: bold;
  font-family: 'PlainGermanica', Arial, sans-serif !important;
  animation: fade-in 0.5s ease-out;
  padding: 0.5rem;
}

/* Responsive design */
@media (max-width: 768px) {
  .header {
    padding: 0.5rem;
  }

  .header-container {
    flex-direction: column;
  }

  .header-image {
    height: 100px;
    margin: 0.5rem 0;
  }

  .header h1 {
    font-size: 2rem;
    letter-spacing: 0.3rem;
  }

  .war-gallery-buttons button {
    font-size: 1.2rem;
  }

  .about p {
    font-size: 1em;
  }

  .collections h1 {
    font-size: 1.5rem;
  }

  .image-gallery figure,
  .image-container-home figure {
    max-width: 100%;
  }

  .footer img {
    height: 2rem;
  }
  .footer p {
    font-family: "Deutsch";
  }
    /* Collections Page */
    .image-container-home {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;
    }
    
    .image-container-home figure {
      margin: 0;
      width: 25%;
      max-width: 180px;
      animation: fade-in 0.5s ease-out;
    }
    
    .image-container-home img {
      /* width: 50%; */
      /* height: auto; */
      border: 2px solid var(--accent-color);
    }
    .page-collection-button {
      background-color: var(--accent-color);
      color: var(--background-color);
      border-radius: 5px;
      font-size: 1.2rem;
      width: 7rem;
      max-width: 10rem;
      cursor: pointer;
      font-family: 'PlainGermanica', Arial, sans-serif !important;
      animation: fade-in 0.5s ease-out;
      margin: 0.5rem;
      padding: 0.5rem;
    }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 1rem;
    position: relative;
  }

  .header-image {
    height: 80px;
  }

  .war-gallery-buttons button {
    font-size: 1rem;
    width: 7rem;
    height: 3rem; 
    margin: .2rem .4rem;
    animation: fade-in 0.5s ease-out;
    line-height: 1.2;
  }
  

  .collections h1 {
    font-size: 1.2rem;
  }

  .page-collection-button,
  .wallet-connect-buttons button {
    font-size: 0.9rem;
    line-height: 1.2;
  }

  /* Collections Page */
  .image-container-home {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .image-container-home figure {
    margin: 0;
    width: 25%;
    max-width: 180px;
    animation: fade-in 0.5s ease-out;
  }
  
  .image-container-home img {
    /* width: 50%; */
    /* height: auto; */
    border: 2px solid var(--accent-color);
  }
  .page-collection-button {
    background-color: var(--accent-color);
    color: var(--background-color);
    border-radius: 5px;
    font-size: 1rem;
    width: 7rem;
    max-width: 10rem;
    cursor: pointer;
    font-family: 'PlainGermanica', Arial, sans-serif !important;
    animation: fade-in 0.5s ease-out;
    margin: 0.5rem;
    padding: 0.5rem;
    line-height: 1.2;
  }
}
