.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.page-counter {
    font-size: 14px;
    color: #333;
}

.close-modal {
    padding: 5px 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.pdf-container {
    flex-grow: 1;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    position: relative;
}

.react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-pdf__Page {
    max-width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
}

.react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
}

.download-link {
    text-align: center;
    margin-top: 10px;
}

.download-link a {
    color: #4CAF50;
    text-decoration: none;
}

.download-link a:hover {
    text-decoration: underline;
}

.loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
