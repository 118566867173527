.clubhouse {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 20px 20px 20px;
}

.clubhouse-tab-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -3rem;
    margin-right: 1.3rem;
}

.clubhouse-buttons-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 .5rem;
}

.clubhouse-button {
    background-color: var(--accent-color);
    color: var(--background-color);
    border-radius: 5px;
    font-size: 1.5rem;
    width: 10rem;
    cursor: pointer;
    font-family: 'PlainGermanica', Arial, sans-serif !important;
    animation: fade-in 0.5s ease-out;
    margin: 0.5rem 0;
    padding: 10px;
    border: none;
    min-height: 44px;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
}

.clubhouse-button:hover {
    background-color: #e0e0e0;
}

/* Styles for the wallet button */
.wallet-adapter-button-trigger {
    background-color: var(--accent-color) !important;
    color: var(--background-color) !important;
    border-radius: 5px !important;
    font-size: 1rem !important;
    width: 10rem !important;
    cursor: pointer !important;
    font-family: 'PlainGermanica', Arial, sans-serif !important;
    animation: fade-in 0.5s ease-out !important;
    margin: 0.5rem 0 !important;
    padding: 10px !important;
    border: none !important;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5) !important;
}

.wallet-adapter-button-trigger:hover {
    background-color: #e0e0e0 !important;
}
.chat-messages {
    border: 1px solid #ccc;
    padding: 20px;
    height: 400px;
    overflow-y: auto;
}

.message {
    margin-bottom: 10px;
}

.user {
    font-weight: bold;
    margin-right: 10px;
}

form {
    margin-top: 20px;
    display: flex;
}

input[type="text"] {
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
}

button[type="submit"] {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.library {
    padding: 20px;
}

.pdf-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.pdf-thumbnail {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    transition: transform 0.2s;
}

.pdf-thumbnail:hover {
    transform: scale(1.05);
}

.pdf-thumbnail .react-pdf__Document {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    margin-bottom: 10px;
}

.pdf-thumbnail .react-pdf__Page {
    max-width: 100%;
    max-height: 100%;
}

.pdf-thumbnail p {
    font-size: 14px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Updated styles for category buttons */
.category-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    min-height: 100px;
}
/* ... (keep all other styles unchanged) ... */

.category-button {
    background-color: var(--accent-color);
    color: var(--background-color);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'PlainGermanica', Arial, sans-serif !important;
    width: calc(20% - 10px);
    box-sizing: border-box;
    min-height: 100px;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
}
.category-button:hover {
    background-color: #e0e0e0;
}

.category-button.active {
    background-color: #4CAF50;
    color: white;
}

/* ... (keep all other styles unchanged) ... */

/* Responsive adjustments */
@media (max-width: 1000px) {
    .category-button {
        width: calc(25% - 10px);
        min-height: 80px;
    }
}

@media (max-width: 768px) {
    .category-button {
        width: calc(33.33% - 10px);
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .category-button {
        width: calc(50% - 10px);
        font-size: 0.8rem;
    }
    
    .clubhouse-button,
    .wallet-adapter-button-trigger {
        font-size: 1.2rem !important;
    }
}

/* Add this new media query for iOS devices */
@supports (-webkit-touch-callout: none) {
    .clubhouse-button,
    .wallet-adapter-button-trigger,
    .category-button {
        font-family: 'PlainGermanica', Arial, sans-serif !important;
    }
}
